<template>
  <div class="page_">
    <!-- 企业健康服务服务 -->
    <div class="navbar_">
      <div class="main_">
        <img src="../../assets/medilink/product/health/header.jpg" alt="" />
        <div class="text_">
          <p>{{ $t("PAS2[14]") || "企业健康服务" }}</p>
        </div>
      </div>
      <navbar :showHeader="showHeader" :nowIndex="4"></navbar>
    </div>
    <!-- 专业的企业健康服务 -->
    <div class="w">
      <p class="title_ animate">{{ $t("PAS2[14]") || "专业的企业健康服务" }}</p>
      <div class="third_ w">
        <img src="../../assets/medilink/product/health/about.jpg" alt="" />
        <div class="text_ animate">
          <p class="s_title_">{{ $t("PAS3[0]") || "服务概述" }}</p>
          <div class="s_section_">
            {{
              $t("PAS3[1]") ||
                "保持健康，治疗疾病一直是中间带关注和服务的两大重点。中间带在为健康保险产品提供第三方管理服务的同时，注重挖掘其直付网络的医疗资源，努力为保险公司、企业团体和个人提供增值的健康促进和便捷就医服务。中间带通过与医疗机构及合作伙伴合作，归纳和整理网络医疗资源，形成多种健康与医疗服务，助力客户身体健康，就医便利。"
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- 跨境医疗服务 -->
    <p class="title_ teg_title_ animate">
      {{ $t("PAS3[2]") || "跨境医疗服务" }}
    </p>
    <div class="teg_con_ w">
      <div class="dot_sec_">
        <img class="dot" src="../../assets/medilink/product/dot.png" alt="" />
      </div>
      <div class="teg_text_">
        <div class="teg_txt_title_ animate">
          <div>
            {{
              $t("PAS3[3]") ||
                "在当今健康意识日益增强的社会，寻求到其它国家医疗将变得与在自己国家寻求医疗一样容易。"
            }}
          </div>
          <div>
            {{
              $t("PAS3[4]") ||
                "基于这种情况，中间带通过其在东南亚、中国和美国密切合作的医疗服务机构，为会员推出了跨境医疗服务。"
            }}
          </div>
          <div>
            {{
              $t("PAS3[5]") ||
                "这就使得由一个国家旅行到另一个国家去寻求医学治疗所需要的复杂过程变得彻底简单了。"
            }}
          </div>
        </div>
      </div>
      <img
        class="teg_img_"
        src="../../assets/medilink/product/health/medical.jpg"
        alt=""
      />
    </div>
    <!-- 我们如何工作 -->
    <p class="title_ animate">{{ $t("PAS3[6]") || "我们如何工作" }}</p>
    <div class="w work_">
      <img src="../../assets/medilink/product/health/work.png" alt="" />
      <div class="w_txt_ animate">
        {{
          $t("PAS3[7]") ||
            `中间带利用其优选的医疗机构，不仅能为会员提供医疗咨询和信息，还可以为会员安排到知名的医疗机构进行专科治疗。
        简而言之，中间带将作为一站式协调和联络员为会员寻找有关特种专科治疗的建议。在选定的医疗机构进行治疗及相关服务所产生的医疗费用将全部由会员自行负责。`
        }}
      </div>
    </div>
    <!-- 我们的服务 -->
    <div class="w service_">
      <p class="title_ ser_title_ animate">
        {{ $t("PAS3[8]") || "我们的服务" }}
      </p>
      <ul>
        <li class="service_introduce_">
          <img
            class="sev_img_"
            src="../../assets/medilink/product/health/service1.png"
            alt=""
          />
          <span>{{ $t("PAS3[9]") || "确定知名医疗机构" }}</span>
        </li>
        <li class="sev_arrow_">
          <img src="../../assets/medilink/product/health/arrow.png" alt="" />
        </li>
        <li class="service_introduce_">
          <img
            class="sev_img_"
            src="../../assets/medilink/product/health/service2.png"
            alt=""
          />
          <span>{{ $t("PAS3[10]") || "安排医疗预约" }}</span>
        </li>
        <li class="sev_arrow_">
          <img src="../../assets/medilink/product/health/arrow.png" alt="" />
        </li>
        <li class="service_introduce_">
          <img
            class="sev_img_"
            src="../../assets/medilink/product/health/service3.png"
            alt=""
          />
          <span>{{ $t("PAS3[10]") || "安排医疗预约" }}</span>
        </li>
        <li class="sev_arrow_">
          <img src="../../assets/medilink/product/health/arrow.png" alt="" />
        </li>
        <li class="service_introduce_">
          <img
            class="sev_img_"
            src="../../assets/medilink/product/health/service4.png"
            alt=""
          />
          <span>{{ $t("PAS3[11]") || "安排专科治疗" }}</span>
        </li>
      </ul>
    </div>
    <!-- 会员将享受到的福利 -->
    <p class="title_ mem_title_ animate">
      {{ $t("PAS3[12]") || "会员将享受到的福利" }}
    </p>
    <div class="member_ w">
      <div class="cicle_ cicle_1_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_yl.png"
          alt=""
        />
        <span>{{
          $t("PAS3[13]") ||
            "方便进入中间带已建立的具有二级和三级医疗资质的网络医疗机构。"
        }}</span>
      </div>
      <div class="cicle_ cicle_2_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_bl.png"
          alt=""
        />
        <span>{{ $t("PAS3[14]") || "便利获取跨境医疗信息。" }}</span>
      </div>
      <div class="cicle_ cicle_3_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_zl.png"
          alt=""
        />
        <span>{{ $t("PAS3[15]") || "一站式跨境治疗安排。" }}</span>
        <img
          class="img_"
          src="../../assets/medilink/product/health/member1.png"
          alt=""
        />
        <span>{{ $t("PAS3[16]") || "致电咨询" }}</span>
        <img
          class="img_"
          src="../../assets/medilink/product/health/member2.png"
          alt=""
        />
        <span>{{ $t("PAS3[17]") || "会员资格确认" }}</span>
        <img
          class="img_"
          src="../../assets/medilink/product/health/member3.png"
          alt=""
        />
        <span>{{ $t("PAS3[18]") || "启用服务网络" }}</span>
        <img
          class="img_"
          src="../../assets/medilink/product/health/member4.png"
          alt=""
        />
        <span>{{ $t("PAS3[19]") || "具体服务安排" }}</span>
        <img
          class="img_"
          src="../../assets/medilink/product/health/member5.png"
          alt=""
        />
        <span>{{ $t("PAS3[20]") || "诊断及治疗" }}</span>
      </div>
    </div>
    <!-- 中医网络 -->
    <div class="w">
      <p class="title_ animate">{{ $t("TCMN[0]") || "中医网络" }}</p>
      <div class="third_ w">
        <img src="../../assets/medilink/product/health/medi.jpg" alt="" />
        <div class="text_">
          <p class="s_title_">{{ $t("TCMN[1]") || "服务概述" }}</p>
          <div class="s_section_">
            {{
              $t("TCMN[2]") ||
                `
            中医，发源于中国，是中国传统医学。在今天，寻求中医治疗的人群数量增长迅速。
            中医理论发源于几千年前，通过不断对自然、宇宙、人体的探索而产生。中医治疗包含了中草药、针灸、饮食疗法、指压按摩等。气功和太极拳等也与中医紧密联系。
            虽然中医在东亚被广泛接受，但在很多西方国家只是作为医疗体系中一种替代的疗法。
            经在中国开展业务，中间带能够帮助会员提供可以进行多种疾病中医治疗的中医医疗机构。
            `
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- 如何来实现 -->
    <p class="real_title_ title_ animate">
      {{ $t("TCMN[3]") || "如何来实现？" }}
    </p>
    <div class="real_ w">
      <img src="../../assets/medilink/product/health/feaure.jpg" alt="" />
      <ul class="list_">
        <li>
          <div class="real_cicle_"></div>
          <span>{{
            $t("TCMN[4]") ||
              "中间带可以安排所选定的中医医疗机构来提供医疗建议，安排多种疾病的治疗。"
          }}</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>{{
            $t("TCMN[5]") ||
              "中间带会收集您的需求信息并反馈至医生，根据您的情况为您推荐相应的、合适的中医医疗机构。"
          }}</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>{{ $t("TCMN[6]") || "中间带会进一步帮助会员寻求治疗。" }}</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>{{
            $t("TCMN[7]") ||
              "在实际的中医治疗中所发生的一切费用都需要由会员自行承担。"
          }}</span>
        </li>
      </ul>
    </div>
    <!-- 会员福利 -->
    <p class="title_ mem_title_ animate">{{ $t("BFM[0]") || "会员福利" }}</p>
    <div class="member_fl_ w">
      <div class="cicle_ cicle_1_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_fl_1.png"
          alt=""
        />
        <span>{{ $t("BFM[1]") || "方便会员到已选定的中医医疗机构就诊" }}</span>
      </div>
      <div class="cicle_ cicle_2_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_fl_2.png"
          alt=""
        />
        <span>{{
          $t("BFM[2]") || "前往中国进行旅行医疗会在中间带的协助下变得很容易。"
        }}</span>
      </div>
      <div class="cicle_ cicle_3_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_fl_3.png"
          alt=""
        />
        <span>{{
          $t("BFM[3]") || "会员可以提前对其治疗进行计划，并且享受轻松的旅行。"
        }}</span>
      </div>
      <div class="cicle_ cicle_4_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_fl_4.png"
          alt=""
        />
        <span>{{
          $t("BFM[4]") || "通过中间带可以很容易的获取中医信息。"
        }}</span>
      </div>
    </div>
    <!-- 第二诊疗意见 -->
    <p class="title_ mem_title_ animate">
      {{ $t("BFM[5]") || "第二诊疗意见" }}
    </p>
    <div class="sp_con_ w">
      <img src="../../assets/medilink/product/health/second.jpg" alt="" />
      <div class="sp_text_">
        <p class="sp_txt_title_">{{ $t("BFM[6]") || "服务概述" }}</p>
        <div>
          {{
            $t("BFM[7]") ||
              "第二医疗意见可以让您足不出户就能够得到来自世界顶级医疗机构的最优秀的专家对您的疾病诊断提出的建议。"
          }}
        </div>
      </div>
    </div>
    <!-- 如何来获取第二诊疗意见？ -->
    <p class="title_ teg_title_ animate">
      {{ $t("BFM[8]") || "如何来获取第二诊疗意见？" }}
    </p>
    <div class="teg_con_ w">
      <div class="dot_sec_">
        <img class="dot" src="../../assets/medilink/product/dot.png" alt="" />
      </div>
      <div class="teg_text_">
        <div class="teg_txt_title_ animate">
          <div>
            {{
              $t("BFM[9]") ||
                "作为第二医疗意见服务的有效会员，如果您被确诊（或被怀疑）患有严重的疾病，则您可以进行第二医疗意见的申请。"
            }}
          </div>
          <div>
            {{
              $t("BFM[10]") ||
                "您的疾病必须是我们所提供的疾病列表中所列举的疾病类型。一旦满足要求，根据您的授权，中间带会在您的协助下收集所有相关的医疗记录并且发送至所选医疗机构获取第二医疗意见。"
            }}
          </div>
          <div>
            {{
              $t("BFM[11]") ||
                "通常来讲，第二医疗意见会在所选医疗机构收到材料后的十个工作日内完成。"
            }}
          </div>
        </div>
      </div>
      <img
        class="teg_img_"
        src="../../assets/medilink/product/health/second_sx.jpg"
        alt=""
      />
    </div>
    <!-- 服务范围 -->
    <p class="real_title_ title_ animate">{{ $t("SOS[0]") || "服务范围" }}</p>
    <div class="real_ w">
      <img src="../../assets/medilink/product/health/service_fw.jpg" alt="" />
      <ul class="list_">
        <li>
          <div class="real_cicle_"></div>
          <span>{{
            $t("SOS[1]") || "为您，您的家人以及您的医生提供可靠的医疗信息。"
          }}</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>{{
            $t("SOS[2]") ||
              "第二医疗意见的相关工作人员会为您来进行申请第二医疗意见相关的工作，免除您长途旅行就医的困扰。"
          }}</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>{{
            $t("SOS[3]") ||
              "通常第二医疗意见会在所选医疗机构收到完整的材料后十个工作日内完成。"
          }}</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>{{
            $t("SOS[4]") ||
              "中间带会在会员得到第二医疗意见之后继续为会员提供住院安排等力所能及的服务。"
          }}</span>
        </li>
      </ul>
    </div>
    <!-- 会员福利 -->
    <p class="title_ mem_title_ animate">{{ $t("BFM1[0]") || "会员福利" }}</p>
    <div class="member_fl2_ w">
      <div class="cicle_ cicle_1_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_fl2_1.png"
          alt=""
        />
        <span>{{
          $t("BFM1[1]") ||
            "节省了会员自己寻求第二医疗意见的差旅费用和住宿费用。"
        }}</span>
      </div>
      <div class="cicle_ cicle_2_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_fl2_2.png"
          alt=""
        />
        <span>{{
          $t("BFM1[0]") || "减轻会员的心理压力，让会员更好的了解第二医疗意见。"
        }}</span>
      </div>
      <div class="cicle_ cicle_3_">
        <img
          class="img_"
          src="../../assets/medilink/product/health/member_fl2_3.png"
          alt=""
        />
        <span>{{
          $t("BFM1[0]") ||
            "无论是否有既往症，只要满足第二医疗意见疾病列表所函纳的疾病，均可以进行第二医疗意见的申请。"
        }}</span>
      </div>
    </div>
    <div class="w1200_">
      <h2 class="animate">{{ $t("EMA[0]") || "紧急救援服务" }}</h2>
      <div class="flex_wrap_">
        <div class="img_wrap_">
          <img src="../../assets/medilink/rescue/2.png" />
        </div>
        <div class="right_wrap_ animate">
          <h4>{{ $t("EMA[1]") || "服务内容简介" }}</h4>
          <p>
            {{
              $t("EMA[2]") ||
                "紧急医疗援助服务（MEA）是对旅行中遇到突发疾病、意外损伤或有医疗需求的人们提供援助服务，包括将患者转运至医疗机构救治或转运回居住地。紧急医疗援助服务的目的是为急需医疗帮助的人们提供及时与合适的解决方案，或及时安排，将他们转送到更适合的地点进行治疗。"
            }}
          </p>
        </div>
      </div>
      <h2 class="animate">{{ $t("EMA[3]") || "我们的服务" }}</h2>
      <ul class="our_service_">
        <li>
          <div><img src="../../assets/medilink/rescue/3.png" /></div>
          <div>{{ $t("EMA[4]") || "电话医生咨询服务" }}</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/4.png" /></div>
          <div>{{ $t("EMA[5]") || "医疗推荐及安排预约" }}</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/5.png" /></div>
          <div>{{ $t("EMA[6]") || "安排医生出诊安排入院担保" }}</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/6.png" /></div>
          <div>{{ $t("EMA[7]") || "安排紧急医疗转运" }}</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/7.png" /></div>
          <div>{{ $t("EMA[9]") || "医疗状况跟踪并向家属转达信息" }}</div>
        </li>
        <li>
          <div><img src="../../assets/medilink/rescue/8.png" /></div>
          <div>{{ $t("EMA[10]") || "境外遗体运送回国" }}</div>
        </li>
      </ul>
      <h2 class="animate">{{ $t("EMA[11]") || "我们如何工作？" }}</h2>
      <div class="flex_wrap_">
        <div class="img_wrap_">
          <div>
            <img src="../../assets/medilink/rescue/9.png" />
          </div>
          <div class="bottom_">
            <p class="light_">
              <span class="num_">01</span>{{ $t("EMA[12]") || "紧急医疗援助" }}
            </p>
            <p>
              {{
                $t("EMA[13]") ||
                  "中间带的紧急医疗援助服务覆盖全球，在出现紧急医疗需求时，可快速启动援助服务，根据求助车具体情况安排对应的服务。"
              }}
            </p>
          </div>
        </div>
        <div class="img_wrap_">
          <div>
            <img src="../../assets/medilink/rescue/10.png" />
          </div>
          <div class="bottom_">
            <p class="light_">
              <span class="num_">02</span
              >{{ $t("EMA[14]") || "24小时多语言服务" }}
            </p>
            <p>
              {{
                $t("EMA[15]") ||
                  "中间带提供全天侯24小时，多语言援助服务。可以为在国外旅行的会员及时提的提供在线或实地紧急医疗援助服务或安排。"
              }}
            </p>
          </div>
        </div>
      </div>
      <h2 class="animate">{{ $t("EMA[16]") || "会员将享受到的福利" }}</h2>
      <div class="flex_wrap_ member_welfare_">
        <div class="item_">
          <i></i>{{ $t("EMA[17]") || "境外旅行期间的一种放心和安心感。" }}
        </div>
        <div class="item_">
          <i></i
          >{{ $t("EMA[18]") || "方便地享受中间带遍布于东南亚、中国和其他" }}
        </div>
      </div>
    </div>
    <!-- 服务范围 -->
    <!-- <p class="real_title_ title_ animate">服务范围</p>
    <div class="real_ w">
      <img src="../../assets/medilink/product/health/service_fw.jpg"
           alt="">
      <ul class="list_">
        <li>
          <div class="real_cicle_"></div>
          <span>为您，您的家人以及您的医生提供可靠的医疗信息。</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>第二医疗意见的相关工作人员会为您来进行申请第二医疗意见相关的工作，免除您长途旅行就医的困扰。</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>通常第二医疗意见会在所选医疗机构收到完整的材料后十个工作日内完成。</span>
        </li>
        <li>
          <div class="real_cicle_"></div>
          <span>中间带会在会员得到第二医疗意见之后继续为会员提供住院安排等力所能及的服务。</span>
        </li>
      </ul>
    </div> -->
    <!-- 会员福利 -->
    <!-- <p class="title_ mem_title_ animate">会员福利</p>
    <div class="member_fl2_ w">
      <div class="cicle_ cicle_1_">
        <img class="img_"
             src="../../assets/medilink/product/health/member_fl2_1.png"
             alt="">
        <span>节省了会员自己寻求第二医疗意见的差旅费用和住宿费用。</span>
      </div>
      <div class="cicle_ cicle_2_">
        <img class="img_"
             src="../../assets/medilink/product/health/member_fl2_2.png"
             alt="">
        <span>减轻会员的心理压力，让会员更好的了解第二医疗意见。</span>
      </div>
      <div class="cicle_ cicle_3_">
        <img class="img_"
             src="../../assets/medilink/product/health/member_fl2_3.png"
             alt="">
        <span>无论是否有既往症，只要满足第二医疗意见疾病列表所函纳的疾病，均可以进行第二医疗意见的申请。</span>
      </div>
    </div> -->
    <footerbar></footerbar>
    <sideBar v-show="showBar"></sideBar>
  </div>
</template>
<script>
import footerbar from "./components/footer.vue";
import navbar from "./components/navbar.vue";
import sideBar from "./components/sideBar.vue";
export default {
  components: { navbar, footerbar, sideBar },
  data() {
    return {
      showHeader: false,
      height: 0,
      showBar: false
    };
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll();
    };
    this.startAnimation();
  },
  methods: {
    handleScroll(e) {
      var top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      this.height = top;
      this.startAnimation();
    },
    startAnimation() {
      let elementArr = document.querySelectorAll(".animate");
      let bodyHeight = document.body.clientHeight;
      let heightArr = [];
      for (var i = 0; i < elementArr.length; i++) {
        heightArr.push(elementArr[i].offsetTop + 100);
      }
      for (var j = 0; j < heightArr.length; j++) {
        if (
          heightArr[j] >= this.height &&
          heightArr[j] < this.height + bodyHeight
        ) {
          elementArr[j].classList.add("slideUpIn60");
        }
      }
    }
  },
  watch: {
    height: {
      handler(newHeight, oldHeight) {
        if (newHeight > 400) {
          this.showBar = true;
          if (newHeight > oldHeight) {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        } else {
          this.showHeader = false;
          this.showBar = false;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.page_ {
  padding: 0;
  .w1200_ {
    width: 1200px;
    margin: 0 auto 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  h2 {
    margin: 75px 0;
    text-align: center;
    font-size: 24px;
    font-family: MicrosoftYaHeiUISemibold;
  }
  h4 {
    font-size: 18px;
    color: #000000;
    margin-bottom: 17px;
  }
  .member_welfare_ {
    padding: 0 42px 42px;
    .item_ {
      margin: 59px;
      background: rgba(0, 166, 60, 0.03);
      font-size: 14px;
      border-radius: 24px;
      line-height: 48px;
      text-align: center;
    }
  }
  .flex_wrap_ {
    display: flex;
    .img_wrap_,
    .item_ {
      flex: 1;
      position: relative;
      .bottom_ {
        padding: 65px;
        position: absolute;
        left: 0;
        bottom: 0;
        .light_ {
          color: #00a63c;
          .num_ {
            font-size: 50px;
          }
        }
      }
    }
    .right_wrap_ {
      flex: 1;
      padding: 64px 50px 0 41px;
      font-size: 14px;
    }
  }
  .our_service_ {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    li {
      width: 155px;
      text-align: center;
    }
  }
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .navbar_ {
    position: relative;
    width: 100%;
    .main_ {
      width: 100%;
      position: relative;
      color: #fff;
      & > img {
        height: auto;
        width: 100%;
        object-fit: cover;
      }
      .text_ {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        font-weight: bold;
        font-size: 33px;
        text-align: center;
      }
    }
  }
  .title_ {
    margin: 0 auto;
    margin-top: 74px;
    margin-bottom: 75px;
    text-align: center;
    // width: 300px;
    height: 32px;
    font-size: 24px;
    font-family: MicrosoftYaHeiUISemibold;
    color: rgba(0, 0, 0, 1);
    line-height: 32px;
  }

  .third_ {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
    }
    .text_ {
      background-color: #fbfbfb;
      padding-left: 41px;
      padding-top: 47px;
      width: 50%;
      height: 100%;
      line-height: 24px;
      .s_title_ {
        // width: 72px;
        height: 24px;
        font-size: 18px;
        font-family: MicrosoftYaHeiUISemibold;
        color: rgba(0, 0, 0, 1);
        margin-top: 30px;
      }
      .s_section_ {
        width: 509px;
        height: 165px;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI;
        color: #333;
        margin-top: 17px;
      }
    }
  }
  .service_ {
    .ser_title_ {
      text-align: center;
    }
    ul {
      margin-top: 74px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      .service_introduce_ {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        list-style: none;
        img {
          width: 154px;
          height: 154px;
          margin-bottom: 21px;
        }
      }
      .sev_arrow_ {
        display: block;
        padding-top: 60px;
        > img {
          width: 17px;
          height: 20px;
        }
      }
    }
  }
  .line_ {
    height: 1px;
    background-color: #00a63c;
    margin-top: 33px;
    .line_dot_ {
      position: relative;
      .dot_1_ {
        position: absolute;
        left: 58px;
        transform: translateY(-50%);
        width: 16.7px;
        height: 16.7px;
        background: rgba(0, 166, 60, 1);
        border-radius: 50%;
      }
      .dot_2_ {
        left: 301px;
      }
      .dot_3_ {
        left: 545px;
      }
      .dot_4_ {
        left: 788px;
      }
      .dot_5_ {
        left: 1032px;
      }
    }
  }
  .line_text_ {
    margin-top: 24px;
    position: relative;
    .line_text_ {
      position: absolute;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .line_text_1_ {
      left: 2px;
      width: 126px;
      height: 57px;
    }
    .line_text_2_ {
      left: 257px;
      width: 98px;
      height: 38px;
    }
    .line_text_3_ {
      left: 502px;
      width: 98px;
      height: 38px;
    }
    .line_text_4_ {
      left: 732px;
      width: 126px;
      height: 38px;
    }
    .line_text_5_ {
      left: 978px;
      width: 126px;
      height: 38px;
    }
  }
  .mem_title_ {
    margin-bottom: 82px;
  }
  .member_ {
    height: 243px;
    border: 1px solid #eee;
    .cicle_ {
      margin-top: 56px;
      float: left;
      display: flex;
      align-items: center;
      padding-left: 49px;
      text-align: center;
      height: 48px;
      background: rgba(0, 166, 60, 0.03);
      border-radius: 24px;
      .img_ {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 14px;
        padding-left: 4px;
      }
    }
    .cicle_1_ {
      width: 530px;
      margin-left: 59px;
      margin-right: 78px;
    }
    .cicle_2_ {
      width: 386px;
      margin-right: 71px;
    }
    .cicle_3_ {
      > span {
        margin-right: 50px;
      }
      width: 1060px;
      margin-left: 59px;
    }
  }
  .sp_title_ {
    color: rgba(0, 166, 60, 1);
    margin-top: 79px;
    margin-bottom: 83px;
    img {
      width: 21px;
      height: 28px;
      margin-right: 5px;
      vertical-align: center;
    }
    > span {
      font-size: 24px;
    }
  }
  .sp_con_ {
    height: 350px;
    > img {
      width: 50%;
      height: 100%;
    }
    .sp_text_ {
      float: right;
      padding-left: 41px;
      width: 50%;
      .sp_txt_title_ {
        margin-top: 100px;
        margin-bottom: 16px;
        width: 180px;
        height: 24px;
        font-size: 18px;
        color: rgba(0, 0, 0, 1);
        line-height: 24px;
      }
      span {
        width: 509px;
        height: 170px;
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  .teg_title_ {
    margin-top: 82px;
    margin-bottom: 83px;
  }
  .teg_con_ {
    height: 381px;
    .dot_sec_ {
      float: left;
      height: 100%;
      width: 76px;
      .dot {
        width: 100%;
        height: 62px;
      }
    }
    .teg_text_ {
      float: left;
      width: 35%;
      height: 100%;
      margin-left: 30px;
      .teg_txt_title_ {
        line-height: 24px;
        width: 423px;
        font-size: 14px;
        transform: translateY(50%);
        div {
          width: 100%;
        }
      }
    }
    .teg_img_ {
      float: right;
      width: 50%;
      height: 100%;
    }
  }
  .benefit_title_ {
    margin-top: 83px;
    margin-bottom: 82px;
  }
  .benefit_ {
    height: 198px;
    border: 1px solid #eee;
    .cicle_ {
      margin-top: 42px;
      float: left;
      display: flex;
      align-items: center;
      padding-left: 49px;
      text-align: center;
      height: 48px;
      background: rgba(0, 166, 60, 0.03);
      border-radius: 24px;
      .img_ {
        width: 22px;
        height: 22px;
      }
      span {
        padding-left: 10px;
      }
    }
    .cicle_1_ {
      width: 345px;
      margin-left: 80px;
      margin-right: 56px;
    }
    .cicle_2_ {
      width: 336px;
    }
    .cicle_3_ {
      width: 464px;
      margin-left: 130px;
      margin-right: 20px;
    }
    .cicle_4_ {
      width: 322px;
    }
  }
  .sys_title_ {
    margin-top: 83px;
    margin-bottom: 19px;
  }
  .sys_txt_ {
    text-align: center;
    font-family: MicrosoftYaHeiUI;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 172px;
  }
  .foot {
    margin: 0 auto;
    position: relative;
    width: 1570px;
    height: 269px;
    .work_sec_ {
      .w_cicle_ {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        .img_ {
          width: 101px;
          height: 100px;
        }
      }
      .w_cicle_1_ {
        left: 309px;
        top: 83px;
      }
      .w_cicle_2_ {
        left: 511px;
        top: -5%;
      }
      .w_cicle_3_ {
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .w_cicle_4_ {
        left: 968px;
        top: -5%;
      }
      .w_cicle_5_ {
        left: 1159px;
        top: 83px;
      }
    }
    .cicle_sec_b_ {
      position: absolute;
      left: 50%;
      top: 143%;
      width: 900px;
      height: 562px;
      background-color: #eee;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
    .cicle_sec_s_ {
      position: absolute;
      left: 50%;
      top: 143%;
      width: 700px;
      height: 400px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    .logo_ {
      position: absolute;
      left: 50%;
      top: 83%;
      width: 122px;
      height: 15px;
      transform: translate(-50%, -50%);
      > img {
        width: 100%;
      }
    }
    overflow: hidden;
  }
  .work_ {
    display: flex;
    height: 277px;
    > img {
      width: 277px;
      height: 100%;
    }
    .w_txt_ {
      margin-left: 57px;
      margin-top: 111px;
      width: 773px;
      height: 57px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI;
      color: rgba(0, 0, 0, 1);
      line-height: 19px;
    }
  }
  .real_ {
    height: 311px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 288px;
      height: 192px;
      margin-right: 66px;
    }
    .list_ {
      width: 713px;
      height: 192px;
      font-size: 16px;
      .real_cicle_ {
        display: inline-block;
        border-radius: 50%;
        width: 9px;
        height: 9px;
        background: rgba(0, 166, 60, 1);
        margin-right: 11px;
      }
      > li {
        margin-top: 20px;
        &:first-child {
          margin-top: 0px;
        }
        > div {
          font-weight: 700;
        }
      }
    }
  }
  .member_fl_ {
    height: 198px;
    border: 1px solid #eee;
    .cicle_ {
      margin-top: 42px;
      float: left;
      display: flex;
      align-items: center;
      padding-left: 49px;
      text-align: center;
      height: 48px;
      background: rgba(0, 166, 60, 0.03);
      border-radius: 24px;
      .img_ {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 14px;
        padding-left: 4px;
      }
    }
    .cicle_1_ {
      width: 345px;
      margin-left: 98px;
      margin-right: 78px;
    }
    .cicle_2_ {
      width: 482px;
      margin-right: 71px;
    }
    .cicle_3_ {
      margin-left: 155px;
      margin-right: 80px;
      width: 482px;
    }
    .cicle_4_ {
      width: 378px;
      margin-right: 71px;
    }
  }
  .member_fl2_ {
    height: 198px;
    border: 1px solid #eee;
    margin-bottom: 88px;
    .cicle_ {
      margin-top: 42px;
      float: left;
      display: flex;
      align-items: center;
      padding-left: 49px;
      text-align: center;
      height: 48px;
      background: rgba(0, 166, 60, 0.03);
      border-radius: 24px;
      .img_ {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 14px;
        padding-left: 4px;
      }
    }
    .cicle_1_ {
      width: 482px;
      margin-left: 85px;
      margin-right: 78px;
    }
    .cicle_2_ {
      width: 482px;
    }
    .cicle_3_ {
      margin-left: 240px;
      margin-right: 80px;
      width: 750px;
    }
  }
}
</style>
